import { LinkedDoorOrderItem, OrderItem, SupplierOrderItem } from "../../types";
import { isLinkedDoorItem } from "../order_item/isLinkedDoorItem";

export function filterLinkedDoorItems(items: OrderItem[]): LinkedDoorOrderItem[] {
	return items
		.filter(isLinkedDoorItem)
		.filter(
			(item) =>
				item.type === "SUPPLIER" &&
				item.supplierOrderItemType === "LINKED_DOOR" &&
				item.parentId &&
				items.find((i) => i.id === item.parentId)
		);
}
