import {SupplierOrderItem, OrderItem, LinkedDoorOrderItem} from '../../types';
import {filterAnySupplierItems} from './filterAnySupplierItems';


/**
 * 
 * Filters just the supplier items from a specific supplier
 * 
 * @param  {OrderItems} items
 * @param  {string} supplierId
 * @returns SupplierOrderItem
 */
export function filterSupplierItemsBySupplier(items : OrderItem[], supplierId : string) : (SupplierOrderItem | LinkedDoorOrderItem)[] {
    
    const _removeOrphaned = items.filter(item => {
        if(item.type === 'SUPPLIER' && item.supplierOrderItemType == "LINKED_DOOR" && item.parentId && !items.find(i => i.id === item.parentId)) {
            return false;
        }
        return true;
    });
    const _items : (SupplierOrderItem | LinkedDoorOrderItem)[] = filterAnySupplierItems(_removeOrphaned);
    const _filtereditems : (SupplierOrderItem | LinkedDoorOrderItem)[] = _items.filter(item => item.supplierId == supplierId);
    return _filtereditems;
}


    