import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import rx from 'feathers-reactive';

export const socket = io(`${import.meta.env.VITE_PROTOCOL as string}${import.meta.env.VITE_SERVER_URL as string}`);

export const feathersApp = feathers();
feathersApp.configure(auth()).configure(socketio(socket, {
    timeout: 20000
})).configure(rx({
    idField: 'id'
}));




